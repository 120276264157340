import React from "react";
import logo from "../assets/images/librarylogor.png";

function Navbar() {
    return (

        <div className="navbar navbar-glass navbar-sticky">
        <div className="navbar-start w-52">
            <img src={logo} width={40} height={40} alt="logo"></img>

        </div>
        <div className="navbar-center">
            <h5 className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400">High-Tech Leaks</h5>
        </div>
        <div className="navbar-end w-20">
            <div className="">
                <div className="dropdown-container">
                    <div className="dropdown">
                    <label className="flex cursor-pointer px-0" tabIndex="0">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Menu / Menu_Alt_05">
                            <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                    </label>
                    <div className="dropdown-menu dropdown-menu-bottom-left  w-46  bg-glass">

                        <a href="/" className="btn text-base">
                        <ion-icon name="home-outline"></ion-icon> &nbsp; Home<pre>      </pre>
                        </a>
                        <div className="divider my-0"></div>
                        <a href="/books/formal-science" className="btn text-base">
                        <ion-icon name="book-outline"></ion-icon> &nbsp; Blog<pre>       </pre> 
                        </a>
                        <br />
                        <a href="/tutorials/programming" className="btn text-base">
                        <ion-icon name="reader-outline"></ion-icon>  &nbsp; Tools<pre>       </pre>
                        </a>
                        <br />
                        <a href="/disclaimer" className="btn text-base">
                        <ion-icon name="information-circle-outline"></ion-icon> &nbsp; Disclaimer<pre>   </pre>
                        </a>
                        <br />
                        <a href="/about" className="btn text-base">
                        <ion-icon name="help-circle-outline"></ion-icon>  &nbsp; About<pre>      </pre>
                        </a>
                    </div>

                    </div>
                </div>
            </div>

        </div>
        </div>





    );
  }
  export default Navbar;