import React from "react";
import ArticleListing from "./Article";
import {Helmet} from "react-helmet";

function Sport(){
    return(
        <div>
            <br /><br />
            <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Articles covering innovations with regards to sport." />
        <meta property="og:description" content="Learn more about the latest innovations and gadgets with regards to sport." />
        </Helmet> 
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Sports & Entertainment</h1>
                <ArticleListing category={"sports"}></ArticleListing>
            </section>
        </div>
    )
}

export default Sport;