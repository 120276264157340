import React from "react";
import {Helmet} from "react-helmet";

function About() {
    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="Why we built the High-Tech Leaks website." />
        </Helmet>      
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                  The digital landscape is constantly evolving. 
                  New technologies are created every single day. 
                  At the same time, some other technologies become immediately obsolete. 
                  Not everybody is tech-savvy. Many people struggle to keep pace with this ever-changing technological world. 
                  That is precisely the reason that motivated us to build this website. 
                  <b>We </b> intend to constantly write articles discussing the latest technological innovations, news, and gadgets on this platform. 
                  But who are <b>We </b>?
                  </p>
                  <p> 
                  We, refers to <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> , our software development company that designed and implemented this website.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;