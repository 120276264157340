import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import TechNews from "./components/News";
import Computing from "./components/Computing";
import Phones from "./components/Phones";
import Televisions from "./components/Televisions";
import Music from "./components/Music";
import Automotive from "./components/Automotive";
import Toys from "./components/Toys";
import Security from "./components/Security";
import HomeLighting from "./components/HomeLighting";
import Sport from "./components/Sport";
import Other from "./components/Other";
import NewView from "./components/NewsView";
import ArticleView from "./components/ArticleView";
import Disclaimer from "./components/Disclaimers";
import About from "./components/About";
import Contact from "./components/Contact";
import Term from "./components/Term";
import Policy from "./components/Policy";
import Horoscope from "./components/Horoscope";
import NotFound  from "./components/NotFound";
import Cookies from "./components/Cookies";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>
            
            <Route path = "/news" element = { <TechNews></TechNews> } ></Route>
            <Route path = "/computing" element = { <Computing></Computing> } ></Route>
            <Route path = "/phones" element = { <Phones></Phones> } ></Route>
            <Route path = "/televisions" element = { <Televisions></Televisions> } ></Route>
            <Route path = "/music" element = { <Music></Music> } ></Route>
            <Route path = "/automotive" element = { <Automotive></Automotive> } ></Route>
            <Route path = "/toys" element = { <Toys/> } ></Route>
            <Route path = "/security" element = { <Security/> } ></Route>
            <Route path = "/home" element = { <HomeLighting/> } ></Route>
            <Route path = "/sports" element = { <Sport/> } ></Route>
            <Route path = "/other" element = { <Other/> } ></Route>


            <Route path = "/read/:id"  element = { <ArticleView></ArticleView> } ></Route>
            <Route path = "/read/news/:id"  element = { <NewView></NewView> } ></Route>
            <Route path = "/disclaimer" element = { <Disclaimer></Disclaimer> } ></Route>
            <Route path = "/about" element = { <About/> } ></Route>
            <Route path = "/contact" element = { <Contact/> } ></Route>
            <Route path = "/terms" element = { <Term/> } ></Route>
            <Route path = "/policy" element = { <Policy/> } ></Route>
            <Route path = "/cookies" element = { <Cookies/> } ></Route>
            <Route path = "/horoscope" element = { <Horoscope></Horoscope> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App