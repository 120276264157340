import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Music(){
    return(
        <div>
            <br /><br />
            <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Articles covering the music industry." />
            <meta property="og:description" content="Latest innovations, news and gadgets of the music industry." />
            </Helmet>            
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Music</h1>
                <ArticleListing category={"music"}></ArticleListing>
            </section>
    
        </div>
    )
}

export default Music;