import React from "react";
import {Helmet} from "react-helmet";

export default function Cookies(){
    return (
        <>
         <br /> <br />
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Cookies policy" />
          <meta property="og:description" content="Cookies policy of the High-Tech Leaks website."/>
        </Helmet>         
          <div className="py-6 sm:py-12  bg-base-100">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4 ">
                <h2 className="mx-auto text-xl text-base-content"><b>Cookies Policy</b></h2>
                <div className="divide-y divide-gray-300/50 ">
                  <div className="space-y-6 py-8 text-base leading-7 text-base text-base-content">
                  <h5>
                  Please read this cookie policy carefully before using 
                  <span className="font-bold text-purple-500 text-base-content text-base"> hightechleaks.com </span> website  
                  operated by <a className="text-blue-500 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft </a>.
                  <br/>✤ <b>What are cookies?</b><br/>
Cookies are simple text files that are stored on your computer or mobile device by a website’s server. 
Each cookie is unique to your web browser. 
It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.
What types of cookies do we use?
<br/>✤ <b>Necessary cookies</b><br/>
Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features. 
For example, these cookies let us recognize that you have created an account and have logged into that account.
<br/>✤ <b>Functionality cookies</b><br/>
Functionality cookies let us operate the site in accordance with the choices you make. 
For example, we will recognize your username and remember how you customized the site during future visits.
<br/>✤ <b>Analytical cookies</b><br/>
These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. 
These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the website.
<br/>✤ <b>How to delete cookies?</b><br/>
If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. 
Alternatively, you can visit <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://www.internetcookies.com" target="_blank" rel="noreferrer">Internetcookies</a>, 
which contains comprehensive information on how to do this on a wide variety of browsers and devices. 
You will find general information about cookies and details on how to delete cookies from your device.
<br/>✤ <b>Contacting us</b><br/>
If you have any questions about this policy or our use of cookies, please <a className="text-blue-400 hover:text-blue-600 font-bold" href="/contact" >contact us</a>.


                        </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    
      );
}